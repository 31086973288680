const actionStrings = {
  LOGIN_SUCCESSFUL: 'loginSuccessful',
  INVALID_LOGIN_FLOW: 'invalidLoginFlow',
  SET_WRONG_ROLE: 'setWrongRole',
  SET_ID_TOKEN: 'setIdToken',
  SET_LOGIN_DATA: 'setLoginData',
  SET_CLAIMS: 'setClaims',

  USER_SELECTED: 'userSelected',
  CLEAR_USER_SELECTED: 'clearUserSelected',

  SET_ELECTION_LIST: 'setElectionList',
  SET_ELECTION_KEY: 'setElectionKey',
  ELECTION_SELECTED: 'electionSelected',
  ELECTION_FOUND: 'electionFound',
  CLEAR_ELECTION_SELECTED: 'clearElectionSelected',
  SET_AUDIT_LIST: 'setAuditList',
  SET_VOTER_LIST: 'setVoterList',

  SET_OFFICE_LIST: 'setOfficeList',
  OFFICE_SELECTED: 'officeSelected',
  OFFICE_FOUND: 'officeFound',

  CANDIDATE_SELECTED: 'candidateSelected',
  SET_CANDIDATE_LIST: 'setCandidateList',

  ERROR: 'programError'
}

export default actionStrings;
