import React, { useState, useEffect } from "react";
import {CardBody, Table} from "reactstrap";
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime'
import './Results.css';

const AuditLog = ({ voterLookup, auditLookup, officeLookup, candidateLookup }) => {

  const [data, setData] = useState([]);
  const [turnout, setTurnout] = useState(0);
  const [numberVoted, setNumberVoted] = useState(0);

  const sortByDatestamp = (a,b) => b.datestamp - a.datestamp;

  useEffect(() => {
    if (!voterLookup || !auditLookup) return;
    let auditKeys = Object.keys(auditLookup);
    let voters = []
    auditKeys.forEach(key => {
      if (!voters.find(_key => _key === key)) {
        voters.push(key);
      }
    })
    setNumberVoted(voters.length);
    setTurnout(voters.length / Object.keys(voterLookup).length);
  }, [voterLookup, auditLookup]);

  useEffect(() => {
    if (!voterLookup || !auditLookup || !officeLookup) return;
    let $data = [];
    Object.keys(auditLookup).forEach(key => {
      let obj = JSON.parse(JSON.stringify(auditLookup[key]));
      obj.voter = voterLookup[obj.voterKey];
      obj.office = officeLookup[obj.officeKey];
      obj.candidate = candidateLookup[obj.candidateKey];
      $data.push(obj);
    });
    setData($data.sort(sortByDatestamp));
  }, [voterLookup, auditLookup, officeLookup, candidateLookup])

  return (
    <CardBody className='audit-log-table-container'>
      <Table className="audit-log-table">
        <thead>
          <tr>
            <th colSpan={2} className="text-left">Mouse-over the voter name to view the IP address</th>
            <th colSpan={2} className="text-right">Voter turnout: {numberVoted} out of {voterLookup ? Object.keys(voterLookup).length : '0'} = {(turnout*100).toFixed(0)}%</th>
          </tr>
          <tr>
            <th>Date/Time</th>
            <th className="text-left">Voter name</th>
            <th className="text-left">Office</th>
            <th className="text-left">Candidate</th>
          </tr>
        </thead>
        <tbody>
      {
        data.map(item => {
          let voterName = item.voter ? item.voter.lastName+', '+item.voter.firstName : 'Key: '+item.voterKey;
          return (
            <tr key={item.key} title={'IP: '+item.ipAddress}>
              <td className="text-center">{format(fromUnixTime(item.datestamp), 'E yyyy-MM-dd HH:mm:ss')}</td>
              <td className="text-left audit-log-pointer">{voterName}</td>
              <td className="text-left">{item.office.name}</td>
              <td className="text-left">{item.candidate.lastName}, {item.candidate.firstName}</td>
            </tr>
          )
        })
      }
        </tbody>
      </Table>
    </CardBody>
  )
}

export default AuditLog;
