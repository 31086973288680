import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase/app';
import as from '../../reducer/actionStrings';
import actionCreator from "../../reducer/actionCreator";
import constants from "../../utility/constants";
import { Card, CardHeader, CardBody, Row, Col, Input, Label,
  Button, InputGroup, InputGroupAddon } from 'reactstrap';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Information from "../Information/Information";
import Voters from "../Voters/Voters";
import Candidates from "../Candidates/Candidates";
import Settings from "../Settings/Settings";
import Offices from "../Offices/Offices";
import Results from "../Results/Results";
import SampleBallot from '../SampleBallot/SampleBallot';
import initElection from '../../models/initElection';
import './Election.css';

const Election = () => {

  const [windowMode, setWindowMode] = useState('electionInformation'); // 'candidateList" | 'voterList'
  const [election, setElection] = useState(null);

  const db = firebase.firestore();
  const dispatch = useDispatch();
  const claims = useSelector(state => state.claims);
  const electionList = useSelector(state => state.electionList);
  const electionKey = useSelector(state => state.electionKey);

  useEffect(() => {
    let election = electionList?.find(election => election.key === electionKey);
    setElection(election);
  }, [electionKey, electionList]);

  const selectElectionClick = evt => {
    let electionKey = evt.currentTarget.value;
    setWindowMode('electionInformation');
    dispatch(actionCreator(as.SET_ELECTION_KEY, {electionKey}));
  }

  const changeWindowMode = evt => {
    setWindowMode(evt.currentTarget.value);
  }

  const add = () => {
    let electionRef = db
      .collection(constants.ELECTION_LIST_KEY)
      .doc();
    let newElection = initElection(electionRef.id, claims.user_id);
    electionRef
      .set(newElection)
      .then(() => {
        dispatch(actionCreator(as.SET_ELECTION_KEY, {electionKey: newElection.key}))
        setWindowMode('electionInformation');
      })
      .catch(error => {
        dispatch(actionCreator(as.ERROR, {errorCode: error.code}) );
      })
  }

  const getContents = () => {
    if (!electionList) {
      return (
        <Row>
          <Col>
            <h2 className="text-center">Loading election information...</h2>
          </Col>
        </Row>
      );
    } else if (windowMode === 'settings') {
      return <Settings db={db} />
    } else if (election) {
      if (windowMode === 'electionInformation') {
        return <Information />
      } else if (windowMode === 'voterList') {
        return <Voters db={db} />
      } else if (windowMode === 'candidateList') {
        return <Candidates db={db} />
      } else if (windowMode === 'settings') {
        return <Settings db={db} />;
      } else if (windowMode === 'offices') {
        return <Offices />;
      } else if (windowMode === 'results') {
        return <Results db={db} />;
      } else if (windowMode === 'ballot') {
        return <SampleBallot db={db} />;
      } else {
        return null;
      }
    } else {
      return (
        <Row>
          <Col>
            <h2 className="text-center">Select an election...</h2>
          </Col>
        </Row>
      );
    }
  }

  return (
    <Card>
      <Header />
      <Card>
        <CardHeader className="election-header">
          <Row className="election-separator">
            <Col md={2} className="text-right label-center">
              <Label>Election: </Label>
            </Col>
            <Col md={4}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <Button block color="primary" onClick={add}>New</Button>
                </InputGroupAddon>
                <Input type="select" name="election" id="election" value={electionKey ? electionKey : ''} onChange={selectElectionClick}>
                  <option value="">Select an election...</option>
                  {
                    electionList ?
                      electionList.map(election => {
                        return (
                          <option key={election.key} value={election.key}>
                            {election.name}
                          </option>
                        )
                      }) : null
                  }
                </Input>
              </InputGroup>
            </Col>
            <Col md={2} className="text-right label-center">
              <Label>Page:</Label>
            </Col>
            <Col md={3}>
              <Input type="select" name="windowMode" id="election" value={windowMode} onChange={changeWindowMode}>
                {
                  !election && <option disabled={election} value="">&nbsp;</option>
                }
                <option disabled={!election} value="electionInformation">Election information</option>
                <option disabled={!election} value="offices">Offices</option>
                <option disabled={!election} value="candidateList">Candidates</option>
                <option disabled={!election} value="voterList">Voter upload</option>
                <option disabled={!election} value="ballot">Sample ballot</option>
                <option disabled={!election} value="results">Election results</option>
                {
                  claims.firebase.sign_in_provider === 'password' ?
                    <option value="settings">Change password</option> : null
                }
              </Input>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {getContents()}
        </CardBody>
      </Card>
      <Footer />
    </Card>
  )
}

export default Election;
