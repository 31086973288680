const initialState = {
  errorCode: null,

  claims: null,
  idToken: null,

  electionKey: null,

  electionList: null,
  candidateList: null,
  auditList: null,
  voterList: null
}

export default initialState;
