import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import React from "react";
import './Ballot.css';

const Ballot = ({ slate, loading }) => {

  const onClick = evt => {
    console.log(evt);
  }

  if (!slate || loading) return (<h4 className="text-center">Loading...</h4>);

  return (
    slate.map(office => {
      return (
        <Card key={office.key} className="ballot">
          <CardHeader>
            <div className="ballot-office-name">{office.name}</div>
            <div className="ballot-vote-for-number">Vote for {office.voteForNumber}</div>
          </CardHeader>
          <CardBody>
            {
              office.candidates.map(candidate => {
                return (
                  <Row key={candidate.key} className="ballot-candidate">
                    <Col md={2} xs={3}>
                      <div data-key={candidate.key} onClick={onClick} className="ballot-blot"></div>
                    </Col>
                    <Col md={10} cs={9}>
                      <div data-key={candidate.key} onClick={onClick} className="ballot-candidate-name">{candidate.firstName} {candidate.lastName}</div>
                    </Col>
                  </Row>
                )
              })
            }
          </CardBody>
        </Card>
      )
    })
  )
}

export default Ballot;
