import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'firebase/firestore';
import { Row, Col, Card, CardHeader, CardBody,
  FormGroup, Label, Input } from 'reactstrap';
import './SampleBallot.css';
import Ballot from '../Ballot/Ballot';
import getCandidates from "../../utility/getCandidates";

const SampleBallot = () => {

  const [criteriaValue, setCriteriaValue] = useState('');
  const [ballotData, setBallotData] = useState(null);
  const [sampleBallot, setSampleBallot] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const electionKey = useSelector(state => state.electionKey);
  const electionList = useSelector(state => state.electionList);
  const election = electionList.find(el => el.key === electionKey);
  const idToken = useSelector(state => state.idToken);

  const criteriaArray = election ? election.criteria.split(',') : [];

  useEffect(() => {
    if (!electionKey || !idToken) return;
    setLoading(true);
    getCandidates(idToken, {electionId: electionKey})
      .then(response => {
        if (response.err) {
          setError(true);
        } else {
          setError(false);
          setBallotData(response.offices);
        }
        setLoading(false);
      })
      .catch(err => {
        setError(true);
      });
  }, [electionKey, idToken]);

  useEffect(() => {
    if (!ballotData) return;
    let filtered = ballotData.filter(office => {
      let cList = office.criteria.split(',');
      return cList.indexOf(criteriaValue) >= 0;
    });
    setSampleBallot(filtered);
    console.log(filtered);
  }, [ballotData, criteriaValue])

  const onChange = evt => {
    setCriteriaValue(evt.currentTarget.value);
  }

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <h4 className="text-center">Sample ballot</h4>
          </Col>
        </Row>
        <FormGroup row className="text-center">
          <Label for="criteria" md={{size:3, offset:3}}>Show ballot for criteria:</Label>
          <Col md={2}>
            <Input name="criteriaValue" id="criteriaValue" type="select" value={criteriaValue} onChange={onChange}>
              <option value="">Select a criterion</option>
              {
                criteriaArray.map(c => {
                  return <option key={c} value={c}>{c}</option>
                })
              }
            </Input>
          </Col>
        </FormGroup>
        {
          error ?
            <Row>
              <Col>
                <h5 className="text-center error">There was an error retrieving the ballot</h5>
              </Col>
            </Row> : null
        }
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={{size: 6, offset: 3}}>
            <Ballot slate={sampleBallot} loading={loading} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default SampleBallot;
