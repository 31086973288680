import React, { useEffect }  from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Login from '../Login/Login';
import Election from "../Elections/Election";
import getUnixTime from 'date-fns/getUnixTime';
import actionCreator from "../../reducer/actionCreator";
import actionStrings from "../../reducer/actionStrings";
import errorStrings from "../../utility/errorStrings";
import getElectionList from "../../thunks/getElectionList";
import constants from "../../utility/constants";
import useCollectionList from "../../hooks/useCollectionList";
import './App.css';

const App = () => {

  const claims = useSelector(state => state.claims);
  const electionKey = useSelector(state => state.electionKey);
  const dispatch = useDispatch();

  useCollectionList(constants.OFFICE_LIST_KEY, actionStrings.SET_OFFICE_LIST, dispatch, electionKey);
  useCollectionList(constants.CANDIDATE_LIST_KEY, actionStrings.SET_CANDIDATE_LIST, dispatch, electionKey);
  useCollectionList(constants.VOTER_LIST_KEY, actionStrings.SET_VOTER_LIST, dispatch, electionKey);
  useCollectionList(constants.AUDIT_LIST_KEY, actionStrings.SET_AUDIT_LIST, dispatch, electionKey);

  useEffect(() => {
    let now = getUnixTime(new Date());
    if (claims?.expiry <= now) {
      dispatch(actionCreator(actionStrings.ERROR, {errorCode: errorStrings.EXPIRED}));
    }
  }, [dispatch, claims]);

  useEffect(() => {
    if (!claims) return;
    dispatch(getElectionList(claims.user_id));
  }, [claims, dispatch])

  return claims ? <Election /> : <Login />

}

export default App;
