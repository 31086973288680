import React, {useEffect, useState} from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import firebase from 'firebase/app';
import 'firebase/auth';
import './Settings.css';
import '../Elections/Election.css';

const Settings = () => {

  const [password, setPassword] = useState('');
  const [_password, set_Password] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [passwordChangeError, setPasswordChangeError] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [requireRecentLogin, setRequireRecentLogin] = useState(false);

  useEffect(() => {
    setIsValid(password.length > 0 && _password.length > 0 && (password === _password));
  }, [password, _password]);

  const onChange = setter => evt => {
    setter(evt.currentTarget.value);
  }

  const save = () => {
    let user = firebase.auth().currentUser;
    user.updatePassword(password)
      .then(() => {
        setPasswordChangeError(false);
        setPasswordChanged(true);
        setRequireRecentLogin(false)
      })
      .catch(error => {
        if (error.code === "auth/requires-recent-login") {
          setRequireRecentLogin(true);
          setPasswordChangeError(false);
        } else {
          setRequireRecentLogin(false);
          setPasswordChangeError(true);
        }
        setPasswordChanged(false);
      })
  }

  return (
    <Row className="setting-panel">
      <Col md={1}>&nbsp;</Col>
      <Col md={7}>
        <Form>
          <FormGroup row>
            <Label for="password" md={5}>Password:</Label>
            <Col md={7}>
              <Input type="password"
                     name="password"
                     id="password"
                     onChange={onChange(setPassword)}
                     value={password}
                     placeholder="Password" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="confirmPassword" md={5}>Repeat password:</Label>
            <Col md={7}>
              <Input type="password"
                     name="confirmPassword"
                     id="confirmPassword"
                     value={_password}
                     placeholder="Repeat password"
                     onChange={onChange(set_Password)} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={5}>&nbsp;</Label>
            <Col md={7}>
              <Button onClick={save} color="primary" disabled={!isValid} className="control-buttons">Change password</Button>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={5}>&nbsp;</Label>
            <Col md={7}>
              {
                passwordChangeError ?
                  <Label className="password-change-error">There was a problem changing your password</Label> : null
              }
              {
                passwordChanged ?
                  <Label>Your password has been changed</Label> : null
              }
              {
                requireRecentLogin ?
                  <Label className="password-change-error">For increased security to change your password you must reload this webpage and log in again.</Label> : null
              }
            </Col>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  )
}

export default Settings;
