import pkg from '../../../package.json';
import { CardFooter } from 'reactstrap';
import './Footer.css';
import { fromUnixTime, format } from 'date-fns';
import {useSelector} from "react-redux";

const instructionsURL = 'https://docs.google.com/document/d/1P0RP4etNxZOFD8GNVfzL1PifPv-6uPDdSh5jzPColSg';

const Footer = () => {

  let expiry = useSelector(state => state.claims?.expiry);
  let expireTime = expiry && format(fromUnixTime(expiry), 'MMM d, yyyy');
  let expireTimeText = expireTime && <><span>Account expires {expireTime}</span><br/></>;

  return (
    <CardFooter>
      <div className="footer">
        Version {pkg.version}<br/>
        Copyright {pkg.copyright}<br/>
        {expireTimeText}
        <a href={instructionsURL} target="_blank" rel="noreferrer">Instructions</a>
      </div>
    </CardFooter>
  )
}

export default Footer;
