import React from "react";
import {Row, Col} from "reactstrap";
import './Results.css';

const ResultsCandidate = ({ candidates, tallyList }) => {

  const comparison = (a,b) => b.votes - a.votes;

  let _candidates = JSON.parse(JSON.stringify(candidates));

  let winningTotal = 0;
  _candidates = _candidates.map(candidate => {
    candidate.votes = tallyList[candidate.key] ? tallyList[candidate.key] : 0;
    return candidate;
  })
  let candidateHTML = _candidates.sort(comparison).map((candidate, idx) => {
    if (idx === 0) {
      winningTotal = candidate.votes;
    }
    return (
      <Row className={(idx === 0 || candidate.votes === winningTotal) && (winningTotal > 0) ? "candidate-winner candidate-html-row" : "candidate-html-row"} key={candidate.key}>
        <Col md={8} className="text-left">{candidate.firstName} {candidate.lastName}</Col>
        <Col md={4} className="text-center">{candidate.votes}</Col>
      </Row>
    )
  })

  return candidateHTML
}

export default ResultsCandidate;
