import as from './actionStrings';
import produce from 'immer';

const reducer = (state, action) => {

  const newState = produce(state, draft => {

    switch (action.type) {
      case as.SET_ID_TOKEN:
        draft.idToken = action.payload.idToken;
        break;

      case as.SET_CLAIMS:
        draft.claims = action.payload.claims;
        break;

      case as.SET_ELECTION_LIST:
        draft.electionList = action.payload.list;
        break;

      case as.SET_ELECTION_KEY:
        draft.electionKey = action.payload.electionKey;
        draft.officeList = null;
        draft.candidateList = null;
        draft.auditList = null;
        break;

      case as.SET_OFFICE_LIST:
        draft.officeList = action.payload.list;
        break;

      case as.SET_CANDIDATE_LIST:
        draft.candidateList = action.payload.list;
        break;

      case as.SET_AUDIT_LIST:
        draft.auditList = action.payload.list;
        break;

      case as.SET_VOTER_LIST:
        draft.voterList = action.payload.list;
        break;

      case as.CLEAR_ELECTION_SELECTED:
        draft.selectedElection = null;
        break;

      case as.ERROR:
        draft.errorCode = action.payload.errorCode;
        break;

      default:
        break;
    }

  });

  return newState;
}

export default reducer;
