import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Card, CardHeader, CardBody, CardFooter, CardTitle, CardText, Table,
  Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Papa from 'papaparse'
import { produce } from 'immer';
import 'firebase/firestore';
import sha512 from '../../utility/sha512';
import '../Elections/Election.css';
import importVoterList from "../../utility/importVoterList";
import getRandomString from "../../utility/getRandomString";
import './Voters.css';
import actionCreator from "../../reducer/actionCreator";
import actionStrings from "../../reducer/actionStrings";
import errorStrings from "../../utility/errorStrings";
import {deleteCollection} from "../../utility/deleteCollection";
import firebase from "firebase/app";
import constants from "../../utility/constants";

const Voters = () => {

  const LIMIT = 5;
  const [csvData, setCsvData] = useState(null);
  const [skipFirst, setSkipFirst] = useState(false);
  const [importing, setImporting] = useState(false);
  const [importSuccessful, setImportSuccessful] = useState(false);
  const [importFailed, setImportFailed] = useState(false);
  const [error, setError] = useState([]);
  const fieldHeader = {
    password: "Last name, First name, Criteria, ID (or email), Password",
    google: "Last name, First name, Criteria, Email"
  }

  const electionList = useSelector(state => state.electionList);
  const electionKey = useSelector(state => state.electionKey);
  const election = (electionList && electionKey) ? electionList.find(el => el.key === electionKey) : null;
  const voterList = useSelector(state => state.voterList);

  const idToken = useSelector(state => state.idToken);
  const dispatch = useDispatch();
  const db = firebase.firestore();

  const KEY_LENGTH = 20;
  const SALT_LENGTH = 10;
  const JWT_KEY_LENGTH = 15;

  useEffect(() => {
    if (!csvData) return;
    let error = [];
    let missingData = false;
    let criteriaErr = false;
    csvData.forEach((rec, idx) => {
      if (idx === 0) return;
      missingData = missingData || rec[0].length === 0 ||
                        rec[1].length === 0 ||
                        rec[2].length === 0 ||
                        rec[3].length === 0 ||
        (election.authType === 'password' && rec[4].length === 0);
      let criteriaArr = election.criteria.split(',');
      if (criteriaArr.indexOf(rec[2]) === -1) {
        criteriaErr = true;
      }
    });
    if (missingData) error.push('Empty fields are not allowed');
    if (criteriaErr) error.push('At least one voter has invalid criteria');
    setError(error);
  }, [csvData, election.authType, election.criteria]);

  const onChange = evt => {
    let reader = new FileReader();
    reader.onload = function() {
      let csvText = reader.result;
      let csvData = Papa.parse(csvText);
      csvData.data = csvData.data.filter(rec => rec.join('').length > 0);
      setCsvData(csvData.data);
    };
    reader.readAsText(evt.target.files[0]);
  }

  const onChangeSkipFirst = evt => {
    setSkipFirst(evt.currentTarget.checked);
  }

  const onImportClick = () => {
    setImporting(true);
    setImportFailed(false);
    setImportSuccessful(false);
    let _csvData = produce(csvData, draft => {
      if (skipFirst) {
        draft.slice(1);
      }
    })
    _csvData = _csvData.map(rec => {
      if (rec.length < 2) return null;
      let key = getRandomString(KEY_LENGTH);
      let salt = getRandomString(SALT_LENGTH);
      let jwtKey = getRandomString(JWT_KEY_LENGTH);
      let voter = {
        key,
        didVote: false,
        salt,
        jwtKey,
        criteria: rec[2],
        firstName: rec[1],
        lastName: rec[0]
      }
      if (election.authType === 'password') {
        voter.passwordHash = sha512(rec[4], salt);
        voter.id = rec[3]
      } else {
        voter.id = rec[3];
      }
      return voter;
    })
    importVoterList(idToken, {electionId: election.key, importData: _csvData})
      .then(response => {
        if (response.err) {
          if (response.code === errorStrings.TOKEN_EXPIRED) {
            dispatch(actionCreator(actionStrings.ERROR, {errorCode: response.code}));
          } else {
            setImportSuccessful(false);
            setImportFailed(true);
          }
        } else {
          let auditRef = db
            .collection(constants.ELECTION_LIST_KEY)
            .doc(electionKey)
            .collection(constants.AUDIT_LIST_KEY);
          deleteCollection(db, auditRef, 100)
            .then(response => {
              if (response) {
                setImportSuccessful(false);
                setImportFailed(true);
              } else {
                setImportSuccessful(true);
                setImportFailed(false);
              }
            })
        }
        setImporting(false);
      })
      .catch(err => {
        setImportFailed(true);
        setImporting(false);
      })
  }

  const _csvData = csvData ? (skipFirst ? csvData.slice(1,6) : csvData.slice(0,5)) : [];

  return (
    <Row>
      <Col md={9}>
        <Card>
          <CardHeader>
            {
              election ?
                <h5>{election?.voterCount} {election?.voterCount === 1 ? 'voter is' : 'voters are'} registered for this election</h5> : null
            }
          </CardHeader>
          <CardBody>
            <Table size="sm">
              <thead>
                <tr><th className="text-center" colSpan={election.authType === 'password' ? 5 : 4}>First {LIMIT} existing voter records. Passwords are not shown for privacy.</th></tr>
                <tr>
                  <th>Last name</th>
                  <th>First name</th>
                  <th className="text-center">Criteria</th>
                  <th className="text-center">ID/Email</th>
                </tr>
              </thead>
              <tbody>
              {
                voterList.map((voter, idx) => {
                  if (idx >= LIMIT) return null;
                  return (
                    <tr key={voter.key}>
                      <td>{voter.lastName}</td>
                      <td>{voter.firstName}</td>
                      <td className="text-center">{voter.criteria}</td>
                      <td className="text-center">{voter.id}</td>
                    </tr>
                  )
                })
              }
              </tbody>
            </Table>
            <hr/>
            <CardTitle>
              <Form>
                <FormGroup row>
                  <Col md={12}>
                    <h6><span className="import-fields">Data fields: </span>{fieldHeader[election.authType]}</h6>
                    <Label for="restoreFile">Skip first record:</Label>
                    <Input type="checkbox" id="skipFirst" name="skipFirst" onChange={onChangeSkipFirst} checked={skipFirst} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md={4}>
                    <Label for="restoreFile">Choose voter import file</Label>
                    <Input id="voterFile" name="voterFile" accept="text/csv,text/text,.csv,.text,.txt" type="file" onChange={onChange}/>
                  </Col>
                  <Col md={2}>
                    <Button block onClick={onImportClick} disabled={(error.length > 0) || !csvData || importing} color="primary">{importing ? 'Importing...' : 'Import'}</Button>
                  </Col>
                  <Col md={6}>
                    {
                      <p><span className="error import-fields">{error.length > 0 ? error.join(', ') : null}</span></p>
                    }
                    {
                      importSuccessful ? <h5>Voter import was successful!</h5> : null
                    }
                    {
                      importFailed ? <h5 className="import-failed">Voter import failed</h5> : null
                    }
                  </Col>
                </FormGroup>
              </Form>
            </CardTitle>
            <CardText>
              {
                csvData ?
                  <div>
                    <h5>The first five records of import file are below. Check for the proper data field location.</h5>
                    <Table size="sm">
                      <thead>
                        <tr>
                          <th>Last name</th>
                          <th>First name</th>
                          <th>Criteria</th>
                          <th>ID</th>
                          {
                            election.authType === 'password' ? <th>Password</th> : null
                          }
                        </tr>
                      </thead>
                      <tbody>
                      {
                        _csvData.map(rec => (
                          <tr key={rec[3]}>
                            <td>{rec[0]}</td>
                            <td>{rec[1]}</td>
                            <td>{rec[2]}</td>
                            <td>{rec[3]}</td>
                            {
                              election.authType === 'password' ? <td>{rec[4]}</td> : null
                            }
                          </tr>
                        ) )
                      }
                      </tbody>
                    </Table>
                  </div>: null
              }
            </CardText>
            <CardFooter>
              <h6 className="import-warning">NOTE: Importing new voter information will erase the current voter list AND previous election results</h6>
            </CardFooter>
          </CardBody>
        </Card>
      </Col>
      <Col md={3}>
        <Card>
          <CardHeader>
            <h5>CSV field explanation</h5>
          </CardHeader>
          <CardBody>
            <h6><span className="bold-label">Last name</span>: The voter's last name</h6>
            <h6><span className="bold-label">First name</span>: The voter's first name</h6>
            <h6><span className="bold-label">Criteria</span>: One of the criteria values set on the Election Information page (typically the grade level)</h6>
            <h6><span className="bold-label">ID or Email</span>: Identification of the voter. This should be the ID the voter will use to log in. For the username / password authentication method this can be an email, username, or ID number. For the Google authentication method it must be the voter's Google account email. IDs must be unique for each voter.</h6>
            {
              election.authType === 'google' ? null :
                (election.authType === 'password' ? <h6><span className="bold-label">Password</span>: The user's password.</h6> : null)
            }
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Voters;
