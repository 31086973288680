import { useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import actionCreator from '../reducer/actionCreator';
import constants from '../utility/constants';
import actionStrings from "../reducer/actionStrings";

const useCollectionList = (collectionListKey, actionString, dispatch, electionKey) => {
  const db = firebase.firestore();
  useEffect(() => {
    if (!electionKey) return null;
    let unsubscribe = db
      .collection(constants.ELECTION_LIST_KEY)
      .doc(electionKey)
      .collection(collectionListKey)
      .onSnapshot(querySnapshot => {
        let list = [];
        querySnapshot.forEach(rec => {
          list.push(rec.data());
        })
        dispatch(actionCreator(actionString, {err: false, list}));
      },
        err => {
        dispatch(actionCreator(actionStrings.ERROR, {err: true, errorCode: err.code}));
        })
    return () => {
      unsubscribe()
    };
  }, [electionKey, collectionListKey, actionString, dispatch, db])
}

export default useCollectionList;
