const crypto = require('crypto');
const ALGORITHM = 'sha512';

/**
 * SHA512 Hash algorithm with salt
 * @param password
 * @param salt
 * @returns {string}
 */
const sha512 = function(password, salt) {
  let hash = crypto.createHmac(ALGORITHM, salt); /** Hashing algorithm sha512 */
  hash.update(password);
  return hash.digest('hex');
};

module.exports = sha512

