import React, { useState, useEffect } from 'react';
import {useSelector} from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody,
  ListGroup, ListGroupItem, ListGroupItemText,
  Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { produce } from 'immer';
import './Candidates.css';
import constants from "../../utility/constants";
import initCandidate from '../../models/initCandidate';
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import { getRef } from "../../utility/getRef";
import firebase from "firebase/app";

const Candidates = () => {

  const [officeKey, setOfficeKey] = useState(null);
  const [office, setOffice] = useState(null);
  const [candidateKey, setCandidateKey] = useState(null);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);

  const [dirty, setDirty] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [db] = useState(firebase.firestore());

  const electionKey = useSelector(state => state.electionKey);

  const sortByName = (a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
  const sortByNameParts = (a, b) => (a.lastName + ',' + b.lastName).toLowerCase() < (b.lastName + ',' + b.firstName).toLowerCase() ? -1 : 1;
  const sortList = (list, comparator) => {
    return produce(list, draft => {
      let _list = draft.sort(comparator);
      return _list
    })
  }

  const officeList = useSelector(state => {
    return sortList(state.officeList, sortByName);
  });
  const candidateList = useSelector(state => {
    let cl = sortList(state.candidateList, sortByNameParts);
    return cl.filter(c => c.officeId === officeKey);
  });

  const candidate = candidateList.find(c => c.key === candidateKey);

  useEffect(() => {
    if (!officeKey || !officeList) return;
    setOffice(officeList.find(of => of.key === officeKey));
  }, [officeKey, officeList]);

  useEffect(() => {
    if (!candidate) return;
    setLastName(candidate.lastName);
    setFirstName(candidate.firstName);
  }, [candidate])

  const officeItemClicked = evt => {
    let key = evt.currentTarget.getAttribute('data-key');
    setOfficeKey(key);
  }

  const candidateItemClicked = evt => {
    let key = evt.currentTarget.getAttribute('data-key');
    setCandidateKey(key);
  }

  const onChange = setter => evt => {
    setter(evt.currentTarget.value);
    setDirty(true);
  }

  const add = () => {
    let ref = getRef(db, [
      constants.ELECTION_LIST_KEY,
      electionKey,
      constants.CANDIDATE_LIST_KEY,
    ]);
    ref = ref.doc();
    let candidate = initCandidate(ref.id, officeKey);
    ref.set(candidate)
      .then(() => {
        setCandidateKey(candidate.key);
        setDirty(false);
      })
  }

  const del = () => {
    setConfirmModal(true);
  }

  const confirmCandidateDelete = () => {
    let ref = getRef(db, [
      constants.ELECTION_LIST_KEY,
      electionKey,
      constants.CANDIDATE_LIST_KEY,
      candidateKey
    ]);
    ref.delete()
      .then(() => {
        setCandidateKey(null);
      })
    setConfirmModal(false);
  }

  const cancelCandidateDelete = () => {
    setConfirmModal(false);
  }

  const save = () => {
    let ref = getRef(db, [
      constants.ELECTION_LIST_KEY,
      electionKey,
      constants.CANDIDATE_LIST_KEY,
      candidate.key
    ]);
    let c = {...candidate, lastName, firstName}
    ref.set(c)
      .then(() => {
        setDirty(false);
      });
  }

  const cancel = () => {
    setLastName(candidate.lastName);
    setFirstName(candidate.firstName);
    setDirty(false);
  }

  return (
    <Row className="candidate-panel">
      <Col md={3}>
        <Card>
          <CardHeader>
            <h4>Office list</h4>
          </CardHeader>
          <CardBody>
            <ListGroup>
              {
                officeList.map(o => (
                  <ListGroupItem className="office-item"
                                 data-key={o.key}
                                 key={o.key}
                                 onClick={officeItemClicked}
                                 active={o.key === office?.key}>
                    <ListGroupItemText>{o.name}</ListGroupItemText>
                  </ListGroupItem>
                ) )
              }
            </ListGroup>
          </CardBody>
        </Card>
      </Col>
      <Col md={3}>
        <Card>
          <CardHeader>
            <h4>Candidate list</h4>
          </CardHeader>
          <CardBody>
            {
              candidateList.length === 0 ?
                <h6 className="select-text">No candidates</h6> :
                <ListGroup>
                  {
                    candidateList.map(c => (
                      <ListGroupItem className="candidate-item"
                                     data-key={c.key}
                                     key={c.key}
                                     onClick={candidateItemClicked}
                                     active={c.key === candidateKey}>
                        <ListGroupItemText>{c.lastName}, {c.firstName}</ListGroupItemText>
                      </ListGroupItem>
                    ) )
                  }
                </ListGroup>
            }
          </CardBody>
        </Card>
      </Col>
      <Col md={6}>
        <Row className="bottom-spacer">
          <Col md={6}>
            <Button onClick={add} color="primary" disabled={!officeKey} className="control-buttons">Add candidate</Button>
          </Col>
          <Col md={6} className="text-right">
            <Button onClick={save} color="primary" disabled={!dirty} className="control-buttons">Save</Button>
            <Button onClick={cancel} color="primary" disabled={!dirty}>Cancel</Button>
          </Col>
        </Row>
        {
          candidateKey && candidate ?
            <Form>
              <FormGroup row>
                <Label for="lastName" md={5}>Last name:</Label>
                <Col md={7}>
                  <Input type="text"
                         name="lastName"
                         id="lastName"
                         onChange={onChange(setLastName)}
                         value={lastName}
                         placeholder="Last name" />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="firstName" md={5}>First name:</Label>
                <Col md={7}>
                  <Input type="text"
                         name="firstName"
                         id="firstName"
                         onChange={onChange(setFirstName)}
                         value={firstName}
                         placeholder="First name" />
                </Col>
              </FormGroup>
              <Button onClick={del} color="danger" disabled={!candidate}>Delete candidate</Button>
            </Form> :
            <h4>Select a candidate...</h4>
        }
      </Col>
      <ModalConfirm modalFlag={confirmModal}
                    body="Are you sure you want to delete this candidate?"
                    confirmButtonLabel="Yes"
                    className=''
                    confirmCallback={confirmCandidateDelete}
                    cancelCallback={cancelCandidateDelete}
                    title="Confirm delete candidate" />
    </Row>
  )
}

export default Candidates;
