import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody,
  ListGroup, ListGroupItem,
  Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { produce } from 'immer';
import firebase from 'firebase/app';
import 'firebase/firestore';
import constants from "../../utility/constants";
import initOffice from '../../models/initOffice';
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import './Offices.css';
import { getRef } from "../../utility/getRef";
import { deleteCollection } from "../../utility/deleteCollection";

const Offices = () => {

  const [dirty, setDirty] = useState(false);
  const [office, setOffice] = useState(null);
  const [officeKey, setOfficeKey] = useState(null);
  const [criteria, setCriteria] = useState([]);
  const [name, setName] = useState(null);
  const [order, setOrder] = useState(null);
  const [voteForNumber, setVoteForNumber] = useState(null);

  const [confirmModal, setConfirmModal] = useState(false);
  const [db] = useState(firebase.firestore());
  const [deleteError, setDeleteError] = useState(false);

  const electionKey = useSelector(state => state.electionKey);
  const electionList = useSelector(state => state.electionList);
  const election = electionKey && electionList ?
    electionList.find(el => el.key === electionKey) : null;
  const officeList = useSelector(state => {
    return produce(state.officeList, draft => {
      return draft.sort((a,b) => parseInt(a.order,10) < parseInt(b.order, 10) ? -1 : 1)
    })
  });

  useEffect(() => {
    if (!office || !election) return;
    setName(office.name);
    setOrder(office.order);
    setVoteForNumber(office.voteForNumber);
    let officeCriteriaList = office.criteria.split(',');
    let electionCriteriaList = election.criteria.split(',');
    let criteria = electionCriteriaList.map(item => ({
      value: item,
      checked: officeCriteriaList.indexOf(item) >= 0
    }) );
    setCriteria(criteria);
  }, [office, election]);

  useEffect(() => {
    if (!officeKey || !officeList) return;
    setOffice(officeList.find(o => o.key === officeKey));
  }, [officeKey, officeList])

  const officeItemClicked = evt => {
    let key = evt.currentTarget.getAttribute('data-key');
    setOfficeKey(key);
  }

  const onChange = setter => evt => {
    setter(evt.currentTarget.value);
    setDirty(true);
  }

  const add = () => {
    let ref = getRef(db, [
      constants.ELECTION_LIST_KEY,
      electionKey,
      constants.OFFICE_LIST_KEY
    ]);
    ref = ref.doc();
    let office = initOffice(ref.id);
    ref.set(office);
    setOfficeKey(office.key);
    setDirty(true);
  }

  const del = () => {
    setConfirmModal(true);
  }

  const confirmOfficeDelete = () => {
    let candidateRef = db
      .collection(constants.ELECTION_LIST_KEY)
      .doc(electionKey)
      .collection(constants.CANDIDATE_LIST_KEY)
      .where('officeId', '==', officeKey);
    deleteCollection(db, candidateRef, 100)
      .then(() => {
        let ref = getRef(db, [
          constants.ELECTION_LIST_KEY,
          electionKey,
          constants.OFFICE_LIST_KEY,
          officeKey
        ]);
        ref.delete()
          .then(() => {
            setOffice(null);
          })
          .catch(error => {
            setDeleteError('Unable to delete this office ('+error.code+')');
          })
        setConfirmModal(false);
      })
      .catch(error => {
        setDeleteError('Unable to delete the candidates for this office ('+error.code+')');
      })
  }

  const cancelOfficeDelete = () => {
    setConfirmModal(false);
  }

  const save = () => {
    let items = { name, order, voteForNumber };
    items.criteria = criteria.reduce((acc, it) => {
      if (it.checked) {
        acc.push(it.value)
      }
      return acc;
    }, []).join(',');

    let newOffice = {...office, ...items };
    setDirty(false);
    let ref = getRef(db, [
      constants.ELECTION_LIST_KEY,
      electionKey,
      constants.OFFICE_LIST_KEY,
      newOffice.key
    ]);
    ref.update(newOffice)
      .then(() => {
        console.log('office updated');
      });
  }

  const cancel = () => {
    setOffice(officeList.find(o => o.key === office.key));
    setDirty(false);
  }

  const onChangeCriteria = evt => {
    let checked = evt.currentTarget.checked;
    let value = evt.currentTarget.getAttribute('data-value');
    let _criteria = produce(criteria, draft => {
      let item = draft.find(it => it.value === value);
      item.checked = checked;
    })
    setCriteria(_criteria);
    setDirty(true);
  }

  const criteriaListHTML = criteriaList => {
    return (
      <>
        <FormGroup row>
          <Col md={5}>
            <Label>
              Check one or more criteria to allow voters in the criteria to cast ballots for this candidate
            </Label>
          </Col>
          <Col md={7}>
            {
              criteriaList.map(item => {
                return (
                  <FormGroup check key={item.value}>
                    <Input type="checkbox" name={item.value} id={item.value} data-value={item.value} onChange={onChangeCriteria} checked={item.checked} />
                    <Label for={item.value} check className="criteria-label">{item.value}</Label>
                  </FormGroup>
                )
              })
            }
          </Col>
        </FormGroup>
      </>
    )
  }

  if (!officeList) return null;

  return (
    <Row className="candidate-panel">
      <Col md={4}>
        <Card>
          <CardHeader>
            <h4>Office list</h4>
          </CardHeader>
          <CardBody>
            <ListGroup>
              {
                officeList.map(o => (
                  <ListGroupItem className="office-item"
                                 data-key={o.key}
                                 key={o.key}
                                 onClick={officeItemClicked}
                                 active={o.key === office?.key}>
                    {o.name}
                  </ListGroupItem>
                ) )
              }
            </ListGroup>
          </CardBody>
        </Card>
      </Col>
      <Col md={8}>
        <Row className="bottom-spacer">
          <Col md={6}>
            <Button onClick={add} color="primary" className="control-buttons">Add office</Button>
          </Col>
          <Col md={6} className="text-right">
            <Button onClick={save} color="primary" disabled={!dirty} className="control-buttons">Save</Button>
            <Button onClick={cancel} color="primary" disabled={!dirty}>Cancel</Button>
          </Col>
        </Row>
        {
          office ?
            <Form>
              <FormGroup row>
                <Label for="lastName" md={5}>Name:</Label>
                <Col md={7}>
                  <Input type="text"
                         name="name"
                         id="name"
                         onChange={onChange(setName)}
                         value={name}
                         placeholder="Office name" />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="firstName" md={5}>Display order:</Label>
                <Col md={7}>
                  <Input type="number"
                         name="order"
                         id="order"
                         onChange={onChange(setOrder)}
                         value={order}
                         placeholder="Display order" />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="firstName" md={5}>Vote for this many:</Label>
                <Col md={7}>
                  <Input type="number"
                         name="voteForNumber"
                         id="voteForNumber"
                         onChange={onChange(setVoteForNumber)}
                         value={voteForNumber}
                         placeholder="Vote for this many" />
                </Col>
              </FormGroup>
              {criteriaListHTML(criteria)}
              <Button onClick={del} color="danger" disabled={!office}>Delete this office</Button>
              {
                deleteError ? <h6 className="error">{deleteError}</h6> : null
              }
            </Form> :
            <h4>Select an office...</h4>
        }
      </Col>
      <ModalConfirm modalFlag={confirmModal}
                    body="Are you sure you want to delete this office?"
                    confirmButtonLabel="Yes"
                    className=''
                    confirmCallback={confirmOfficeDelete}
                    cancelCallback={cancelOfficeDelete}
                    title="Confirm delete candidate" />
    </Row>
  )
}

export default Offices;
