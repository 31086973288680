import React from 'react';
import { useSelector } from 'react-redux';
import './ErrorHandler.css';
import {Card, CardBody, CardHeader} from "reactstrap";
import errorStrings from "../../utility/errorStrings";

const ErrorHandler = props => {

  const errorCode = useSelector(state => state.errorCode);
  const email = useSelector(state => state?.claims?.email);

  const errorTextList1 = {
    [errorStrings.INTERNET_ERROR]: 'Unable to connect to the Election-Monkey servers',
    [errorStrings.FIRESTORE_ERROR]: 'Unable to retrieve data',
    [errorStrings.FETCH_FAIL]: 'API is not working',
    [errorStrings.PERMISSION_DENIED]: 'Permission denied',
    [errorStrings.EXPIRED]: 'The subscription for '+email+' has expired',
    [errorStrings.TOKEN_EXPIRED]: 'Your session timed out.  Please log in again'
  }

  const errorTextList2 = {
    [errorStrings.INTERNET_ERROR]: 'Please check your internet connection.',
    [errorStrings.EXPIRED]: 'Please contact info@election-monkey.com to renew'
  }

  if (errorCode) {
    return (
      <Card>
        <CardHeader className="text-center">
          <h1>Election Monkey</h1>
        </CardHeader>
        <CardBody>
          <h4 className="text-center">{errorTextList1[errorCode]}</h4>
          <h5 className="text-center">{errorTextList2[errorCode]}</h5>
        </CardBody>
      </Card>
    )
  } else {
    return props.children
  }

}

export default ErrorHandler;
