import React, {useEffect, useState} from "react";
import { useSelector } from 'react-redux';
import {Card, CardHeader, CardBody, Row, Col} from "reactstrap";
import AuditLog from "./AuditLog";
import './Results.css';
import ResultsCandidate from "./ResultsCandidate";
import produce from "immer";

const Results = () => {

  const [error] = useState(false);
  const [tallyList, setTallyList] = useState(null);
  const [voterLookup, setVoterLookup] = useState(null);
  const [auditLookup, setAuditLookup] = useState(null);
  const [officeLookup, setOfficeLookup] = useState(null);
  const [candidateLookup, setCandidateLookup] = useState(null);
  const [officeResults, setOfficeResults] = useState(null);

  const auditList = useSelector(state => state.auditList);
  const voterList = useSelector(state => state.voterList);
  const $officeList = useSelector(state => state.officeList);
  const candidateList = useSelector(state => state.candidateList);

  const compareOffices = (a,b) => a.criteria+' '+a.order < b.criteria+' '+b.order ? -1 : 1;
  const officeList = JSON.parse(JSON.stringify($officeList)).sort(compareOffices);

  useEffect(() => {
    if (!auditList) return null;
    let tally = auditList.reduce((acc, item) => {
      if (!acc[item.candidateKey]) {
        acc[item.candidateKey] = 0;
      }
      acc[item.candidateKey] = acc[item.candidateKey] + 1
      return acc;
    }, {})
    setTallyList(tally);
  }, [JSON.stringify(auditList)]);

  const toObject = (objs, obj) => {
    objs[obj.key] = obj;
    return objs
  }

  useEffect(() => {
    if (!voterList || !auditList || !officeList) return null;
    setVoterLookup(voterList.reduce((acc, voter) => toObject(acc, voter), {}));
    setAuditLookup(auditList.reduce((acc, audit) => toObject(acc, audit), {}));
    setOfficeLookup(officeList.reduce((acc, office) => toObject(acc, office), {}));
    setCandidateLookup(candidateList.reduce((acc, candidate) => toObject(acc, candidate), {}));
  }, [JSON.stringify(voterList), JSON.stringify(auditList), JSON.stringify(officeList), JSON.stringify(candidateList)])

  useEffect(() => {
    let officeResults = officeList.map(ofc => {
      let candidates = candidateList.filter(candidate => candidate.officeId === ofc.key);
      return produce(ofc, draft => {
        draft.candidates = candidates;
        return draft;
      })
    });
    setOfficeResults(officeResults);
  }, [JSON.stringify(candidateList), JSON.stringify(officeList)]);

  let cardBody = null;
  if (!auditList || !officeList) {
    cardBody = (
      <CardBody>
        <h4 className="text-center">Loading...</h4>
      </CardBody>
    )
  }

  if (error) {
    cardBody = (
      <CardBody>
        <h4 className="text-center">There was an error retrieving election results</h4>
      </CardBody>
    )
  }

  if (officeResults) {
    cardBody = (
      <CardBody>
        {
          officeResults.map(office => {
            return (
              <Card key={office.key}>
                <CardHeader className="results-office-name">{office.name} ({office.criteria})</CardHeader>
                <CardBody>
                  <ResultsCandidate candidates={office.candidates} tallyList={tallyList} />
                </CardBody>
              </Card>
            )
          })
        }
      </CardBody>
    )
  }

  return (
    <Row>
      <Col md={4}>
        <Card className="text-center">
          <CardHeader>
            <Row>
              <Col>
                <h4 className="text-center">Election results</h4>
              </Col>
            </Row>
          </CardHeader>
          {cardBody}
        </Card>
      </Col>
      <Col md={8}>
        <Card className="text-center">
          <CardHeader>
            <Row>
              <Col>
                <h4 className="text-center">Audit log</h4>
              </Col>
            </Row>
          </CardHeader>
          <AuditLog voterLookup={voterLookup}
                    auditLookup={auditLookup}
                    candidateLookup={candidateLookup}
                    officeLookup={officeLookup} />
        </Card>
      </Col>
    </Row>
  )
}

export default Results;
