import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {Row, Col, Form, FormGroup, Label, Input, Button, InputGroup, InputGroupAddon} from 'reactstrap';
import {format, fromUnixTime, getUnixTime} from 'date-fns';
import constants from "../../utility/constants";
import firebase from 'firebase/app';
import 'firebase/firestore';
import { deleteCollection } from "../../utility/deleteCollection";
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import './Information.css';

const VOTER_LOGIN_INSTRUCTIONS_GOOGLE = 'Log in with your school email and password';
const VOTER_LOGIN_INSTRUCTIONS_PASSWORD = 'Log in with your school ID and password';

const Information = () => {

  const [election, setElection] = useState(null);

  const [reset, setReset] = useState(false);
  const [name, setName] = useState('');
  const [authType, setAuthType] = useState('');
  const [criteriaText, setCriteriaText] = useState('');
  const [criteria, setCriteria] = useState('');
  const [open, setOpen] = useState('');
  const [close, setClose] = useState('');
  const [url, setUrl] = useState('');
  const [voterLoginInstructions, setVoterLoginInstructions] = useState('');

  const [dirty, setDirty] = useState(false);

  const [timeError, setTimeError] = useState(false);
  const [copyError, setCopyError] = useState(false);
  const [copyOk, setCopyOk] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [confirmDeleteResults, setConfirmDeleteResults] = useState(false);
  const [deleteResultsError, setDeleteResultsError] = useState(false);
  const [voterHasPassword, setVoterHasPassword] = useState(false);
  const [electionVoterMismatch, setElectionVoterMismatch] = useState(false);
  const [db] = useState(firebase.firestore());

  const electionList = useSelector(state => state.electionList);
  const electionKey = useSelector(state => state.electionKey);
  const voterList = useSelector(state => state.voterList);

  useEffect(() => {
    if (!electionKey || !electionList) return;
    setElection(electionList.find(election => election.key === electionKey));
  }, [electionKey, electionList])

  useEffect(() => {
    let _close = getUnixTime(new Date(close));
    let _open = getUnixTime(new Date(open));
    setTimeError(election && (_close - _open <= 0));
  }, [election, open, close]);

  useEffect(() => {
    if (!election) return;
    setUrl('https://vote.election-monkey.com?electionId=' + election.key)
  }, [election]);

  useEffect(() => {
    if(!voterList || voterList.length === 0) {
      setVoterHasPassword(false);
    } else {
      let aVoter = voterList[0];
      setVoterHasPassword(typeof aVoter.passwordHash === 'string');
    }
  }, [voterList])

  useEffect(() => {
    setElectionVoterMismatch((voterHasPassword && authType === 'google') || (!voterHasPassword && authType === 'password'));
  }, [voterHasPassword, authType])

  const toTimeText = time => {
    return format(fromUnixTime(time), 'yyyy-MM-dd\'T\'HH:mm');
  }

  useEffect(() => {
    if (!election) return;
    setName(election.name);
    setAuthType(election.authType);
    setCriteriaText(election.criteriaText);
    setCriteria(election.criteria);
    setOpen(toTimeText(election.open));
    setClose(toTimeText(election.close));
    setVoterLoginInstructions(election.voterLoginInstructions);
  }, [election, reset]);

  useEffect(() => {
    if (authType === 'password') {
      setVoterLoginInstructions(VOTER_LOGIN_INSTRUCTIONS_PASSWORD)
    } else if (authType === 'google') {
      setVoterLoginInstructions(VOTER_LOGIN_INSTRUCTIONS_GOOGLE);
    }
  }, [authType])

  const onChange = cb => evt => {
    cb(evt.currentTarget.value);
    setDirty(true);
  }

  const save = () => {
    let items = {name, authType, criteriaText, criteria, voterLoginInstructions};
    let newElection = {...election, ...items};
    newElection.open = getUnixTime(new Date(open));
    newElection.close = getUnixTime(new Date(close));
    db.collection(constants.ELECTION_LIST_KEY)
      .doc(newElection.key)
      .set(newElection)
      .then((err) => {
        setDirty(false);
      });
  }

  const cancel = () => {
    setReset(!reset);
    setDirty(false);
  }

  const del = () => {
    setConfirmModal(true);
  }

  const delResults = () => {
    setConfirmDeleteResults(true);
  }

  const cancelDel = () => {
    setConfirmModal(false);
  }

  const cancelDelResults = () => {
    setConfirmDeleteResults(false);
  }

  const confirmDel = () => {
    const electionRef = db.collection(constants.ELECTION_LIST_KEY)
      .doc(election.key)
    let deleteThese = [
      electionRef.collection(constants.VOTER_LIST_KEY),
      electionRef.collection(constants.AUDIT_LIST_KEY),
      electionRef.collection(constants.OFFICE_LIST_KEY),
      electionRef.collection(constants.CANDIDATE_LIST_KEY)
    ];
    deleteThese = deleteThese.map(ref => deleteCollection(db, ref, 100))
    Promise.all(deleteThese)
      .then(() => {
        electionRef.delete().then(() => {
          setConfirmModal(false);
        });
      })
      .catch(error => {
        setDeleteError('Unable to delete this election ('+error.code+')');
        setConfirmModal(false);
        setTimeout(() => setDeleteError(null), 8000);
      })
  }

  const confirmDelResults = () => {
    const ref = db
      .collection(constants.ELECTION_LIST_KEY)
      .doc(electionKey)
      .collection(constants.AUDIT_LIST_KEY);
    deleteCollection(db, ref, 100)
      .then(() => {
        setConfirmDeleteResults(false);
      })
      .catch(error => {
        setDeleteResultsError('Unable to delete the results ('+error.code+')');
      })
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url)
      .then(() => {
        setCopyOk(true);
      })
      .catch(e => {
        setCopyError(true)
      });
  }

  const copyButtonColor = copyOk ? 'success' : (copyError ? 'error' : 'primary');

  if (!election) return null;
  let now = getUnixTime(new Date());
  return (
    <Row className="information-panel">
      <Col md={1}>&nbsp;</Col>
      <Col md={7}>
        <Form>
          <FormGroup row>
            <Label for="name" md={5}>Election URL:</Label>
            <Col md={7}>
              <InputGroup>
                <Input type="text"
                       value={url}
                       disabled={true} />
                <InputGroupAddon addonType="append">
                  <Button color={copyButtonColor} onClick={copyToClipboard}>Copy</Button>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="name" md={5}>Election name:</Label>
            <Col md={7}>
              <Input type="text"
                     name="name"
                     id="name"
                     data-page="election"
                     onChange={onChange(setName)}
                     value={name}
                     placeholder="Election name" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="authentication" md={5}>Voter authentication:</Label>
            <Col md={7}>
              <Input type="select"
                     name="authType"
                     id="authType"
                     data-page="election"
                     value={authType}
                     onChange={onChange(setAuthType)}>
                <option value="password">User ID and Password</option>
                <option value="google">Google authentication</option>
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="criteriaText" md={5}>Criteria text label:</Label>
            <Col md={7}>
              <Input type="text"
                     name="criteriaText"
                     id="criteriaText"
                     data-page="election"
                     onChange={onChange(setCriteriaText)}
                     value={criteriaText}
                     placeholder="Criteria text label" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="criteria" md={5}>Criteria separated by commas:</Label>
            <Col md={7}>
              <Input type="text"
                     name="criteria"
                     id="criteria"
                     data-page="election"
                     onChange={onChange(setCriteria)}
                     value={criteria}
                     placeholder="Criteria separated by commas" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="open" md={5}>Open election date and time:</Label>
            <Col md={7}>
              <Input type="datetime-local"
                     name="open"
                     id="open"
                     data-page="election"
                     className={timeError ? 'invalid' : ''}
                     value={open}
                     onChange={onChange(setOpen)} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="close" md={5}>Close election date and time:</Label>
            <Col md={7}>
              <Input type="datetime-local"
                     name="close"
                     id="close"
                     data-page="election"
                     className={timeError ? 'invalid' : ''}
                     value={close}
                     onChange={onChange(setClose)} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="voterLoginInstructions" md={5}>Voter login instructions:</Label>
            <Col md={7}>
              <Input type="text"
                     name="voterLoginInstructions"
                     id="voterLoginInstructions"
                     data-page="election"
                     onChange={onChange(setVoterLoginInstructions)}
                     value={voterLoginInstructions}
                     placeholder="Voter log in instructions" />
            </Col>
          </FormGroup>
          <Row>
            <Col md={5}>&nbsp;</Col>
            <Col md={4}>
              <Button onClick={del} color="danger">Delete election</Button>
              {
                deleteError ? <h6 className="error">{deleteError}</h6> : null
              }
            </Col>
            <Col md={3}>
              <Button onClick={delResults} color="danger">Delete results</Button>
              {
                deleteResultsError ? <h6 className="error">{deleteResultsError}</h6> : null
              }
            </Col>
          </Row>
        </Form>
      </Col>
      <Col md={3} className="text-right">
        <Row>
          <Col>
            <Button onClick={save} color="primary" disabled={!dirty || timeError} className="control-buttons">Save</Button>
            <Button onClick={cancel} color="primary" disabled={!dirty || timeError}>Cancel</Button>
          </Col>
        </Row>
        <Row className="election-status text-right">
          <Col>
            { now < election.open && <h6 className="error">This election has not opened yet</h6> }
            { election.close < now && <h6 className="error">This election has already closed</h6> }
          </Col>
        </Row>
        <Row className="election-status text-right">
          <Col>
            { electionVoterMismatch ? <h6 className="error">Your voter information doesn't match what's required for the voter authentication method.  Google-authenticated voters need last name, first name, criteria and email.  ID / Password authenticated voters need last name, first name, criteria, email (or ID number) AND password.  Be sure to upload the correct voter information for the voter authentication type.</h6> : null }
          </Col>
        </Row>
      </Col>
      <ModalConfirm modalFlag={confirmModal}
                    body="Are you sure you want to delete this election?  This will delete all of the voters, candidates, and offices and can't be recovered."
                    confirmButtonLabel="Yes"
                    className=''
                    confirmCallback={confirmDel}
                    cancelCallback={cancelDel}
                    title="Confirm delete election" />
      <ModalConfirm modalFlag={confirmDeleteResults}
                    body="Are you sure you want to delete the results for this election?  These results can't be recovered."
                    confirmButtonLabel="Yes"
                    className=''
                    confirmCallback={confirmDelResults}
                    cancelCallback={cancelDelResults}
                    title="Confirm delete results" />
    </Row>
  )
}

export default Information;
