import { getUnixTime, addDays } from 'date-fns';

const initElection = (key, registrarId) => ({
  authType: 'password',
  close: getUnixTime(addDays(new Date(), 3)),
  criteria: "9,10,11,12",
  criteriaText: 'Grade level',
  key,
  name: 'New election',
  open: getUnixTime(new Date()),
  registrarId,
  voterCount: 0,
  voterLoginInstructions: '',
  voterUpdateTime: null
})

export default initElection
